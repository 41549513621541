var __defProp = Object.defineProperty;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateIn = (member, obj) => Object(obj) !== obj ? __typeError('Cannot use the "in" operator on this value') : member.has(obj);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var __privateMethod = (obj, member, method) => (__accessCheck(obj, member, "access private method"), method);
var __privateWrapper = (obj, member, setter, getter) => ({
  set _(value) {
    __privateSet(obj, member, value, setter);
  },
  get _() {
    return __privateGet(obj, member, getter);
  }
});
var _a2, _b, _LumeElement_instances, handleInitialPropertyValuesIfAny_fn, ____init___, _disposeTemplate, _styleRootNodeRefCountPerTagName, _styleRootNode, _defaultHostStyle, _elementId, _id, _dynamicStyle, setStyle_fn, cleanupStyle_fn, _finalize;
import { render, createComponent } from "solid-js/web";
import { createSignal, $PROXY, untrack, getListener, createEffect, createRoot, getOwner, runWithOwner, onCleanup } from "solid-js";
import { createTmTextarea, css } from "./core.js";
function getInheritedDescriptor(obj, key) {
  let currentProto = obj;
  let descriptor;
  while (currentProto) {
    descriptor = Object.getOwnPropertyDescriptor(currentProto, key);
    if (descriptor) {
      descriptor.owner = currentProto;
      return descriptor;
    }
    currentProto = currentProto.__proto__;
  }
  return void 0;
}
function createSignalFunction(value, options) {
  const [get, set] = createSignal(value, options);
  return function(value2) {
    if (arguments.length === 0) return get();
    return set(
      // @ts-ignore FIXME its ok, value is defined (even if `undefined`)
      value2
    );
  };
}
function signalify(obj, ...props) {
  const proxy = obj[$PROXY];
  if (proxy) return obj;
  const _props = props.length ? props : Object.keys(obj).concat(Object.getOwnPropertySymbols(obj));
  for (const prop of _props) {
    const isTuple = Array.isArray(prop);
    const _prop = isTuple ? prop[0] : prop;
    const initialValue = isTuple ? prop[1] : untrack(() => obj[_prop]);
    __createSignalAccessor(obj, _prop, initialValue);
  }
  return obj;
}
const propsSetAtLeastOnce = /* @__PURE__ */ new WeakMap();
function __isPropSetAtLeastOnce(instance, prop) {
  var _a3;
  return !!((_a3 = propsSetAtLeastOnce.get(instance)) == null ? void 0 : _a3.has(prop));
}
function __trackPropSetAtLeastOnce(instance, prop) {
  if (!propsSetAtLeastOnce.has(instance)) propsSetAtLeastOnce.set(instance, /* @__PURE__ */ new Set());
  propsSetAtLeastOnce.get(instance).add(prop);
}
const isSignalGetter = /* @__PURE__ */ new WeakSet();
function __createSignalAccessor(obj, prop, initialVal) {
  let descriptor = getInheritedDescriptor(obj, prop);
  let originalGet;
  let originalSet;
  const isAccessor = !!((descriptor == null ? void 0 : descriptor.get) || (descriptor == null ? void 0 : descriptor.set));
  if (descriptor) {
    originalGet = descriptor.get;
    originalSet = descriptor.set;
    if (originalGet && isSignalGetter.has(originalGet)) return;
    if (isAccessor && !(originalGet && originalSet)) return warnNotReadWrite(prop);
    if (!isAccessor) {
      if (!descriptor.writable) return warnNotWritable(prop);
      initialVal = descriptor.value;
    }
  }
  const signalStorage = /* @__PURE__ */ new WeakMap();
  const newDescriptor = {
    configurable: true,
    enumerable: descriptor == null ? void 0 : descriptor.enumerable,
    get: isAccessor ? function() {
      __getSignal(this, signalStorage, initialVal)();
      return originalGet.call(this);
    } : function() {
      return __getSignal(this, signalStorage, initialVal)();
    },
    set: isAccessor ? function(newValue) {
      originalSet.call(this, newValue);
      __trackPropSetAtLeastOnce(this, prop);
      const s = __getSignal(this, signalStorage, initialVal);
      s(typeof newValue === "function" ? () => newValue : newValue);
    } : function(newValue) {
      __trackPropSetAtLeastOnce(this, prop);
      const s = __getSignal(this, signalStorage, initialVal);
      s(typeof newValue === "function" ? () => newValue : newValue);
    }
  };
  isSignalGetter.add(newDescriptor.get);
  Object.defineProperty(obj, prop, newDescriptor);
}
function __getSignal(obj, storage, initialVal) {
  let s = storage.get(obj);
  if (!s) storage.set(obj, s = createSignalFunction(initialVal, {
    equals: false
  }));
  return s;
}
function warnNotReadWrite(prop) {
  console.warn(`Cannot signalify property named "${String(prop)}" which had a getter or a setter, but not both. Reactivity on accessors works only when accessors have both get and set. Skipped.`);
}
function warnNotWritable(prop) {
  console.warn(`The \`@signal\` decorator was used on a property named "${String(prop)}" that is not writable. Reactivity is not enabled for non-writable properties.`);
}
let __propsToSignalify = /* @__PURE__ */ new Map();
function __resetPropsToSignalify() {
  __propsToSignalify = /* @__PURE__ */ new Map();
}
const Undefined = Symbol();
function signal(value, context) {
  const {
    kind,
    name,
    metadata
  } = context;
  const props = __propsToSignalify;
  if (context.static) throw new Error("@signal is not supported on static fields yet.");
  queueReactiveDecoratorChecker(props);
  if (kind === "field") {
    if (context.private && name !== "#finalize") throw new Error("@signal is not supported on private fields yet.");
    if (name === "#finalize") __propsToSignalify = /* @__PURE__ */ new Map();
    else props.set(name, {
      initialValue: void 0,
      kind
    });
    return function(initialValue) {
      if (name === "#finalize") {
        const proxy = this[$PROXY];
        if (proxy) return this;
        for (const [prop, propSpec] of props) {
          let initialValue2 = propSpec.initialValue;
          if (!Object.hasOwn(this, prop))
            throw new PropNotFoundError$1(prop);
          __createSignalAccessor(this, prop, initialValue2);
        }
        return;
      }
      props.get(name).initialValue = initialValue;
      return initialValue;
    };
  } else if (kind === "accessor") {
    const {
      get,
      set
    } = value;
    const signalStorage = /* @__PURE__ */ new WeakMap();
    let initialValue = void 0;
    return {
      init: function(initialVal) {
        initialValue = initialVal;
        return initialVal;
      },
      get: function() {
        __getSignal(this, signalStorage, initialValue)();
        return get.call(this);
      },
      set: function(newValue) {
        set.call(this, newValue);
        __trackPropSetAtLeastOnce(this, name);
        const s = __getSignal(this, signalStorage, initialValue);
        s(typeof newValue === "function" ? () => newValue : newValue);
      }
    };
  } else if (kind === "getter" || kind === "setter") {
    const getOrSet = value;
    const initialValue = Undefined;
    if (!Object.hasOwn(metadata, "signalStoragesPerProp")) metadata.signalStoragesPerProp = {};
    const signalsStorages = metadata.signalStoragesPerProp;
    let signalStorage = signalsStorages[name];
    if (!signalStorage) signalsStorages[name] = signalStorage = /* @__PURE__ */ new WeakMap();
    if (!Object.hasOwn(metadata, "getterSetterPairs")) metadata.getterSetterPairs = {};
    const pairs = metadata.getterSetterPairs;
    queueMicrotask(() => {
      if (pairs[name] !== 2) throw new MissingDecoratorError(name);
    });
    if (kind === "getter") {
      pairs[name] ?? (pairs[name] = 0);
      pairs[name]++;
      return function() {
        __getSignal(this, signalStorage, initialValue)();
        return getOrSet.call(this);
      };
    } else {
      pairs[name] ?? (pairs[name] = 0);
      pairs[name]++;
      return function(newValue) {
        getOrSet.call(this, newValue);
        __trackPropSetAtLeastOnce(this, name);
        const s = __getSignal(this, signalStorage, initialValue);
        s(typeof newValue === "function" ? () => newValue : newValue);
      };
    }
  } else throw new InvalidDecorationError();
}
let checkerQueued = false;
function queueReactiveDecoratorChecker(props) {
  if (checkerQueued) return;
  checkerQueued = true;
  queueMicrotask(() => {
    checkerQueued = false;
    if (props === __propsToSignalify) {
      throw new Error(
        // Array.from(map.keys()) instead of [...map.keys()] because it breaks in Oculus browser.
        `Stray @signal-decorated properties detected: ${Array.from(props.keys()).join(", ")}. Did you forget to use the \`@reactive\` decorator on a class that has properties decorated with \`@signal\`?`
      );
    }
  });
}
let PropNotFoundError$1 = class PropNotFoundError extends Error {
  constructor(prop) {
    super(`Property "${String(prop)}" not found on instance of class decorated with \`@reactive\`. Did you forget to use the \`@reactive\` decorator on one of your classes that has a "${String(prop)}" property decorated with \`@signal\`?`);
  }
};
class MissingDecoratorError extends Error {
  constructor(prop) {
    super(`Missing @signal decorator on setter or getter for property "${String(prop)}". The @signal decorator will only work on a getter/setter pair with *both* getter and setter decorated with @signal.`);
  }
}
class InvalidDecorationError extends Error {
  constructor() {
    super("The @signal decorator is only for use on fields, getters, setters, and auto accessors.");
  }
}
const hasOwnProperty = Object.prototype.hasOwnProperty;
function reactive(value, context) {
  if (typeof value !== "function" || context && context.kind !== "class") throw new TypeError("The @reactive decorator is only for use on classes.");
  const Class = value;
  const signalProps = __propsToSignalify;
  __resetPropsToSignalify();
  class ReactiveDecorator extends Class {
    constructor(...args) {
      let instance;
      if (getListener()) untrack(() => instance = Reflect.construct(Class, args, new.target));
      else super(...args), instance = this;
      const proxy = instance[$PROXY];
      if (proxy) return instance;
      for (const [prop, propSpec] of signalProps) {
        let initialValue = propSpec.initialValue;
        if (!(hasOwnProperty.call(instance, prop) || hasOwnProperty.call(Class.prototype, prop))) throw new PropNotFoundError2(prop);
        __createSignalAccessor(instance, prop, initialValue);
      }
      return instance;
    }
  }
  return ReactiveDecorator;
}
class PropNotFoundError2 extends Error {
  constructor(prop) {
    super(`Property "${String(prop)}" not found on instance of class decorated with \`@reactive\`. Did you forget to use the \`@reactive\` decorator on one of your classes that has a "${String(prop)}" property decorated with \`@signal\`?`);
  }
}
function createStoppableEffect(fn) {
  const [running, setRunning] = createSignal(true);
  createEffect(() => running() && fn());
  return {
    stop: () => setRunning(false),
    resume: () => setRunning(true)
  };
}
function Effectful(Base) {
  var _effects, _Effectful_instances, createEffect1_fn, stopEffects1_fn, _owner, _dispose, createEffect2_fn, stopEffects2_fn, _a3;
  return _a3 = class extends Base {
    constructor() {
      super(...arguments);
      __privateAdd(this, _Effectful_instances);
      __privateAdd(this, _effects, /* @__PURE__ */ new Set());
      // Method 2 //////////////////////////////////////////
      // Works, with nesting, no leaks.
      __privateAdd(this, _owner, null);
      __privateAdd(this, _dispose, null);
    }
    /**
     * Create a Solid.js effect. The difference from regular
     * `createEffect()` is that `this` tracks the effects created, so that
     * they can all be stopped with `this.stopEffects()`.
     *
     * Effects can also be stopped or resumed individually:
     *
     * ```js
     * const effect1 = this.createEffect(() => {...})
     * const effect2 = this.createEffect(() => {...})
     *
     * // ...later
     * effect1.stop()
     *
     * // ...later
     * effect1.resume()
     * ```
     */
    createEffect(fn) {
      __privateMethod(this, _Effectful_instances, createEffect2_fn).call(this, fn);
    }
    /**
     * Stop all of the effects that were created.
     */
    stopEffects() {
      __privateMethod(this, _Effectful_instances, stopEffects2_fn).call(this);
    }
  }, _effects = new WeakMap(), _Effectful_instances = new WeakSet(), // Method 1 //////////////////////////////////////////
  // Works fine when not in a parent context, or else currently leaks or has the above mentioned bug while a parent exists.
  createEffect1_fn = function(fn) {
    let effect = null;
    effect = createStoppableEffect(() => {
      if (effect) __privateGet(this, _effects).add(effect);
      createEffect(fn);
      onCleanup(() => __privateGet(this, _effects).delete(effect));
    });
    __privateGet(this, _effects).add(effect);
  }, stopEffects1_fn = function() {
    for (const effect of __privateGet(this, _effects)) effect.stop();
  }, _owner = new WeakMap(), _dispose = new WeakMap(), createEffect2_fn = function(fn) {
    if (!__privateGet(this, _owner)) {
      createRoot((dispose) => {
        __privateSet(this, _owner, getOwner());
        __privateSet(this, _dispose, dispose);
        __privateMethod(this, _Effectful_instances, createEffect2_fn).call(this, fn);
      });
    } else {
      let owner = getOwner();
      while (owner && owner !== __privateGet(this, _owner)) owner = (owner == null ? void 0 : owner.owner) ?? null;
      if (owner === __privateGet(this, _owner)) return createEffect(fn);
      runWithOwner(__privateGet(this, _owner), () => createEffect(fn));
    }
  }, stopEffects2_fn = function() {
    var _a4;
    (_a4 = __privateGet(this, _dispose)) == null ? void 0 : _a4.call(this);
  }, _a3;
}
var _a;
const HTMLElement = globalThis.HTMLElement ?? class HTMLElement2 {
  constructor() {
    throw new Error("@lume/element needs a DOM to operate with! If this code is running during server-side rendering, it means your app is trying to instantiate elements when it shouldn't be, and should be refactored to avoid doing that when no DOM is present.");
  }
};
const root = Symbol("root");
class LumeElement extends (_b = Effectful(HTMLElement), _a2 = root, _b) {
  constructor() {
    super(...arguments);
    __privateAdd(this, _LumeElement_instances);
    // This property MUST be defined before any other non-static non-declared
    // class properties so that the initializer runs before any other properties
    // are defined, in order to detect and handle instance properties that
    // already pre-exist from custom element pre-upgrade time.
    // TODO Should we handle initial attributes too?
    // @ts-expect-error unused
    __privateAdd(this, ____init___, __privateMethod(this, _LumeElement_instances, handleInitialPropertyValuesIfAny_fn).call(this));
    /**
     * When `true`, the custom element will have a `ShadowRoot`. Set to `false`
     * to not use a `ShadowRoot`. When `false`, styles will not be scoped via
     * the built-in `ShadowRoot` scoping mechanism, but by a much more simple
     * shared style sheet placed at the nearest root node, with `:host`
     * selectors converted to tag names.
     */
    __publicField(this, "hasShadow", true);
    /** Options used for the ShadowRoot, passed to `attachShadow()`. */
    __publicField(this, "shadowOptions");
    __publicField(this, _a2, null);
    __privateAdd(this, _disposeTemplate);
    __privateAdd(this, _styleRootNode, null);
    __privateAdd(this, _defaultHostStyle, (hostSelector) => (
      /*css*/
      `${hostSelector} {
		display: block;
	}`
    ));
    __privateAdd(this, _id, __privateWrapper(_a, _elementId)._++);
    __privateAdd(this, _dynamicStyle, null);
  }
  /**
   * Define this class for the given element `name`, or using its default name
   * (`elementName`) if no `name` given. Defaults to using the global
   * `customElements` registry unless another registry is provided (for
   * example a ShadowRoot-scoped registry).
   *
   * If a `name` is given, then the class will be extended with an empty
   * subclass so that a new class is used for each new name, because otherwise
   * a CustomElementRegistry does not allow the same exact class to be used
   * more than once regardless of the name.
   *
   * @returns Returns the defined element class, which is only going to be a
   * different subclass of the class this is called on if passing in a custom
   * `name`, otherwise returns the same class this is called on.
   */
  static defineElement(name = this.elementName, registry = customElements) {
    if (!name) {
      console.warn(`defineElement(): Element name cannot be empty. This is a no-op.`);
      return this;
    }
    if (registry.get(name)) {
      console.warn(`defineElement(): An element class was already defined for tag name ${name}. This is a no-op.`);
      return registry.get(name);
    }
    const alreadyUsed = !!registry.getName(this);
    const Class = alreadyUsed ? class extends this {
    } : this;
    Class.elementName = name;
    registry.define(name, Class);
    return Class;
  }
  /**
   * Subclasses can override this to provide an alternate Node to render into
   * (f.e. a subclass can `return this` to render into itself instead of
   * making a root) regardless of the value of `hasShadow`.
   */
  get templateRoot() {
    if (!this.hasShadow)
      return this;
    if (this[root])
      return this[root];
    if (this.shadowRoot)
      return this[root] = this.shadowRoot;
    return this[root] = this.attachShadow({ mode: "open", ...this.shadowOptions });
  }
  set templateRoot(v) {
    if (!this.hasShadow)
      throw new Error("Can not set root, element.hasShadow is false.");
    if (this[root] || this.shadowRoot)
      throw new Error("Element root can only be set once if there is no ShadowRoot.");
    this[root] = v;
  }
  /** @deprecated `root` is renamed to `templateRoot`, and `root` will be removed in a future breaking version. */
  get root() {
    return this.templateRoot;
  }
  set root(val) {
    this.templateRoot = val;
  }
  /**
   * Define which `Node` to append style sheets to when `hasShadow` is `true`.
   * Defaults to the `this.root`, which in turn defaults to the element's
   * `ShadowRoot`.  When `hasShadow` is `true`, an alternate `styleRoot` is
   * sometimes needed for styles to be appended elsewhere than the root. For
   * example, return some other `Node` within the root to append styles to.
   * This is ignored if `hasShadow` is `false`.
   *
   * This can be useful for fixing issues where the default append of a style
   * sheet into the `ShadowRoot` conflicts with how DOM is created in
   * `template` (f.e.  if the user's DOM creation in `template` clears the
   * `ShadowRoot` content, or etc, then we want to place the stylesheet
   * somewhere else).
   */
  get styleRoot() {
    return this.templateRoot;
  }
  attachShadow(options) {
    if (this[root])
      console.warn("Element already has a root defined.");
    return this[root] = super.attachShadow(options);
  }
  connectedCallback() {
    const template = this.template;
    if (template)
      __privateSet(this, _disposeTemplate, render(typeof template === "function" ? template.bind(this) : () => template, this.templateRoot));
    __privateMethod(this, _LumeElement_instances, setStyle_fn).call(this);
  }
  disconnectedCallback() {
    var _a3;
    this.stopEffects();
    (_a3 = __privateGet(this, _disposeTemplate)) == null ? void 0 : _a3.call(this);
    __privateMethod(this, _LumeElement_instances, cleanupStyle_fn).call(this);
  }
  // not used currently, but we'll leave this here so that child classes can
  // call super, and that way we can add an implementation later when needed.
  adoptedCallback() {
  }
}
_LumeElement_instances = new WeakSet();
handleInitialPropertyValuesIfAny_fn = function() {
  const preUpgradeKeys = Object.keys(this);
  this._preUpgradeValues = /* @__PURE__ */ new Map();
  for (const propName of preUpgradeKeys) {
    const descriptor = Object.getOwnPropertyDescriptor(this, propName);
    if ("value" in descriptor) {
      delete this[propName];
      this._preUpgradeValues.set(propName, descriptor.value);
      queueMicrotask(() => {
        const propSetAtLeastOnce = __isPropSetAtLeastOnce(this, propName);
        if (propSetAtLeastOnce)
          return;
        const inheritsProperty = propName in this.__proto__;
        if (inheritsProperty)
          this[propName] = descriptor.value;
      });
    }
  }
};
____init___ = new WeakMap();
_disposeTemplate = new WeakMap();
_styleRootNodeRefCountPerTagName = new WeakMap();
_styleRootNode = new WeakMap();
_defaultHostStyle = new WeakMap();
_elementId = new WeakMap();
_id = new WeakMap();
_dynamicStyle = new WeakMap();
setStyle_fn = function() {
  const ctor = this.constructor;
  const staticCSS = typeof ctor.css === "function" ? ctor.css = ctor.css() : ctor.css || "";
  const instanceCSS = typeof this.css === "function" ? this.css() : this.css || "";
  if (this.hasShadow) {
    const hostSelector = ":host";
    const staticStyle = document.createElement("style");
    staticStyle.innerHTML = `
				${__privateGet(this, _defaultHostStyle).call(this, hostSelector)}
				${staticCSS}
				${instanceCSS}
			`;
    this.styleRoot.appendChild(staticStyle);
  } else {
    const rootNode = this.getRootNode();
    __privateSet(this, _styleRootNode, rootNode === document ? document.head : rootNode);
    let refCountPerTagName = __privateGet(_a, _styleRootNodeRefCountPerTagName).get(__privateGet(this, _styleRootNode));
    if (!refCountPerTagName)
      __privateGet(_a, _styleRootNodeRefCountPerTagName).set(__privateGet(this, _styleRootNode), refCountPerTagName = {});
    const refCount = refCountPerTagName[this.tagName] || 0;
    refCountPerTagName[this.tagName] = refCount + 1;
    if (refCount === 0) {
      const hostSelector = this.tagName.toLowerCase();
      const staticStyle = document.createElement("style");
      staticStyle.innerHTML = `
					${__privateGet(this, _defaultHostStyle).call(this, hostSelector)}
					${staticCSS ? staticCSS.replaceAll(":host", hostSelector) : staticCSS}
				`;
      staticStyle.id = this.tagName.toLowerCase();
      __privateGet(this, _styleRootNode).appendChild(staticStyle);
    }
    if (instanceCSS) {
      const id = this.tagName.toLowerCase() + "-" + __privateGet(this, _id);
      this.setAttribute(id, "");
      const instanceStyle = __privateSet(this, _dynamicStyle, document.createElement("style"));
      instanceStyle.id = id;
      instanceStyle.innerHTML = instanceCSS.replaceAll(":host", `[${id}]`);
      const rootNode2 = this.getRootNode();
      __privateSet(this, _styleRootNode, rootNode2 === document ? document.head : rootNode2);
      __privateGet(this, _styleRootNode).appendChild(instanceStyle);
    }
  }
};
cleanupStyle_fn = function() {
  do {
    if (this.hasShadow)
      break;
    const refCountPerTagName = __privateGet(_a, _styleRootNodeRefCountPerTagName).get(__privateGet(this, _styleRootNode));
    if (!refCountPerTagName)
      break;
    let refCount = refCountPerTagName[this.tagName];
    if (refCount === void 0)
      break;
    refCountPerTagName[this.tagName] = --refCount;
    if (refCount === 0) {
      delete refCountPerTagName[this.tagName];
      const style = __privateGet(this, _styleRootNode).querySelector("#" + this.tagName);
      style == null ? void 0 : style.remove();
    }
  } while (false);
  if (__privateGet(this, _dynamicStyle))
    __privateGet(this, _dynamicStyle).remove();
};
/**
 * The default tag name of the elements this class instantiates. When using
 * the `@element` decorator, this property will be set to the value defined
 * by the decorator.
 */
__publicField(LumeElement, "elementName", "");
/**
 * Non-decorator users can use this to specify a list of attributes, and the
 * attributes will automatically be mapped to reactive properties. All
 * attributes in the list will be treated with the equivalent of the
 * `@attribute` decorator.
 *
 * The ability to provide a map of attribute names to attribute handlers
 * (`Record<string, AttributeHandler>`) has been deprecaated, and instead
 * that map should be provided via the `static observedAttributeHandlers`
 * property, while this property is now typed to accept only a string array
 * as per DOM spec.
 */
__publicField(LumeElement, "observedAttributes");
/**
 * Non-decorator users can use this instead of `observedAttributes` to
 * specify a map of attribute names to attribute handlers. The named
 * attributes will automatically be mapped to reactive properties, and each
 * attribute will be treated with the corresponding attribute handler.
 *
 * Example:
 *
 * ```js
 * element('my-el')(
 *   class MyEl extends LumeElement {
 *     static observedAttributeHandlers = {
 *       foo: attribute.string(),
 *       bar: attribute.number(),
 *       baz: attribute.boolean(),
 *     }
 *
 *     // The initial values defined here will be the values that these
 *     // properties revert to when the respective attributes are removed.
 *     foo = 'hello'
 *     bar = 123
 *     baz = false
 *   }
 * )
 * ```
 */
__publicField(LumeElement, "observedAttributeHandlers");
__privateAdd(LumeElement, _styleRootNodeRefCountPerTagName, /* @__PURE__ */ new WeakMap());
__privateAdd(LumeElement, _elementId, 0);
_a = LumeElement;
Symbol.metadata ?? (Symbol.metadata = Symbol.for("Symbol.metadata"));
function camelCaseToDash(str) {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
}
function defineProp(obj, prop, value) {
  Object.defineProperty(obj, prop, {
    value,
    writable: true,
    configurable: true,
    enumerable: true
  });
}
const __classFinishers = [];
function attribute(handlerOrValue, context) {
  if (arguments.length === 2)
    return handleAttributeDecoration(handlerOrValue, context, void 0);
  const handler = handlerOrValue;
  return (value, context2) => handleAttributeDecoration(value, context2, handler);
}
function handleAttributeDecoration(value, context, attributeHandler = {}) {
  const { kind, name, private: isPrivate, static: isStatic, metadata } = context;
  const noSignal = Object.hasOwn(metadata, "noSignal") && metadata.noSignal || void 0;
  const useSignal = !(noSignal == null ? void 0 : noSignal.has(name));
  if (typeof name === "symbol")
    throw new Error("@attribute is not supported on symbol fields yet.");
  if (isPrivate)
    throw new Error("@attribute is not supported on private fields yet.");
  if (isStatic)
    throw new Error("@attribute is not supported on static fields.");
  __classFinishers.push((Class) => __setUpAttribute(Class, name, attributeHandler));
  if (kind === "field") {
    const signalInitializer = useSignal ? signal(value, context) : (v) => v;
    return function(initialValue) {
      initialValue = signalInitializer(initialValue);
      if (!("default" in attributeHandler))
        attributeHandler.default = initialValue;
      return initialValue;
    };
  } else if (kind === "getter" || kind === "setter") {
    if (useSignal)
      signal(value, context);
  } else {
    throw new Error("@attribute is only for use on fields, getters, and setters. Auto accessor support is coming next if there is demand for it.");
  }
  return void 0;
}
function __setUpAttribute(ctor, propName, attributeHandler) {
  if (
    //
    !ctor.observedAttributes || !ctor.hasOwnProperty("observedAttributes")
  ) {
    const inheritedAttrs = ctor.__proto__.observedAttributes;
    if (inheritedAttrs && !Array.isArray(inheritedAttrs)) {
      throw new TypeError("observedAttributes is in the wrong format. Did you forget to decorate your custom element class with the `@element` decorator?");
    }
    defineProp(ctor, "observedAttributes", [...inheritedAttrs || []]);
  }
  if (!Array.isArray(ctor.observedAttributes)) {
    throw new TypeError("observedAttributes is in the wrong format. Maybe you forgot to decorate your custom element class with the `@element` decorator.");
  }
  const attrName = camelCaseToDash(propName);
  if (!ctor.observedAttributes.includes(attrName))
    ctor.observedAttributes.push(attrName);
  mapAttributeToProp(ctor.prototype, attrName, propName, attributeHandler);
}
const hasAttributeChangedCallback = Symbol("hasAttributeChangedCallback");
const __attributesToProps = Symbol("attributesToProps");
function mapAttributeToProp(prototype, attr, prop, attributeHandler) {
  if (!prototype[hasAttributeChangedCallback]) {
    prototype[hasAttributeChangedCallback] = true;
    const originalAttrChanged = prototype.attributeChangedCallback;
    prototype.attributeChangedCallback = function(attr2, oldVal, newVal) {
      var _a3, _b2, _c;
      if (originalAttrChanged) {
        originalAttrChanged.call(this, attr2, oldVal, newVal);
      } else {
        (_b2 = (_a3 = prototype.__proto__) == null ? void 0 : _a3.attributeChangedCallback) == null ? void 0 : _b2.call(this, attr2, oldVal, newVal);
      }
      const prop2 = (_c = this[__attributesToProps]) == null ? void 0 : _c[attr2];
      this[prop2.name] = newVal;
    };
  }
  if (!Object.hasOwn(prototype, __attributesToProps)) {
    prototype[__attributesToProps] = { __proto__: prototype[__attributesToProps] || Object.prototype };
  }
  prototype[__attributesToProps][attr] = { name: prop, attributeHandler };
}
const toString = (str) => str;
attribute.string = () => ({ from: toString });
function stringAttribute(value, context) {
  return attribute(attribute.string())(value, context);
}
const toNumber = (str) => +str;
attribute.number = () => ({ from: toNumber });
const toBoolean = (str) => str !== "false";
attribute.boolean = () => ({ from: toBoolean });
function booleanAttribute(value, context) {
  return attribute(attribute.boolean())(value, context);
}
const isAttributeHandler = Symbol("isAttributeHandler");
function element(tagNameOrClass, autoDefineOrContext) {
  let tagName = "";
  let autoDefine = true;
  {
    tagName = tagNameOrClass;
    return (Class, context) => {
      return applyElementDecoration(Class, context, tagName, autoDefine);
    };
  }
}
function applyElementDecoration(Class, context, tagName, autoDefine) {
  if (typeof Class !== "function" || context && context.kind !== "class")
    throw new Error("@element is only for use on classes.");
  const { metadata = {} } = context ?? {};
  const noSignal = Object.hasOwn(metadata, "noSignal") && metadata.noSignal || void 0;
  let Ctor = Class;
  const attrs = Ctor.observedAttributes;
  if (Ctor.hasOwnProperty("elementName"))
    tagName = Ctor.elementName || tagName;
  else
    Ctor.elementName = tagName;
  if (Array.isArray(attrs)) ;
  else if (attrs && typeof attrs === "object") {
    Ctor.observedAttributes = void 0;
    const stack = new Error().stack;
    console.warn("Defining the static observedAttributes property as a map of attribute names to attribute handlers is now deprecated, please use the static observedAttributeHandlers property to define the map instead.\n" + stack);
    const _attrs = attrs;
    for (const prop in _attrs)
      __setUpAttribute(Ctor, prop, attrs[prop]);
  }
  const handlers = Object.hasOwn(Ctor, "observedAttributeHandlers") ? Ctor.observedAttributeHandlers : void 0;
  if (handlers)
    for (const prop of Object.keys(handlers))
      __setUpAttribute(Ctor, prop, handlers[prop]);
  Ctor = reactive(Ctor, context);
  class ElementDecorator extends Ctor {
    constructor(...args) {
      super(...args);
      untrack(() => {
        handlePreUpgradeValues(this);
        const attrsToProps = ElementDecorator.prototype[__attributesToProps] ?? {};
        const propSpecs = Object.values(attrsToProps);
        for (const propSpec of propSpecs) {
          const prop = propSpec.name;
          const useSignal = !(noSignal == null ? void 0 : noSignal.has(prop));
          if (!useSignal)
            continue;
          let isField = false;
          const fieldDesc = Object.getOwnPropertyDescriptor(this, prop);
          const protoDesc = Object.getOwnPropertyDescriptor(Class.prototype, prop);
          let descriptor = fieldDesc;
          if (descriptor)
            isField = true;
          if (!descriptor)
            descriptor = protoDesc;
          if (!descriptor)
            descriptorError(prop);
          const { get, set } = descriptor;
          const isAccessor = !!(descriptor && (get || set));
          const initialValue = isAccessor && get ? get.call(this) : this[prop];
          signalify(isField ? this : Class.prototype, [
            prop,
            initialValue
          ]);
        }
        for (const propSpec of propSpecs) {
          const prop = propSpec.name;
          const handler = propSpec.attributeHandler;
          if (!handler)
            continue;
          if (!("default" in handler))
            handler.default = this[prop];
          let isField = false;
          const fieldDesc = Object.getOwnPropertyDescriptor(this, prop);
          const protoDesc = Object.getOwnPropertyDescriptor(Class.prototype, prop);
          let descriptor = fieldDesc;
          if (descriptor)
            isField = true;
          if (!descriptor)
            descriptor = protoDesc;
          if (!descriptor)
            descriptorError(prop);
          const { get, set, writable } = descriptor;
          const isAccessor = !!(get || set);
          if (!isAccessor && !isField)
            throw new Error(`Cannot map attribute to prototype value property "${String(prop)}". Only prototype getters/setters are supported. Either make the property a class field, or make two separate properties: one for the attribute as a class field, one for the prototype value property.`);
          if (isAccessor && !set || !isAccessor && !writable)
            throw new Error(`An attribute decorator cannot be used on readonly property "${String(prop)}".`);
          let storage;
          if (isAccessor) {
            if (set == null ? void 0 : set[isAttributeHandler])
              continue;
          } else {
            storage = Symbol("attributeHandlerStorage:" + String(prop));
            this[storage] = this[prop];
          }
          const location = isField ? this : Class.prototype;
          const newGetter = isAccessor ? get : (
            // @ts-expect-error indexed access with symbol
            () => this[storage]
          );
          const newSetter = isAccessor ? (
            // function because it will be on the prototype, needs dynamic `this`
            function(value) {
              if (typeof value === "string" || value === null)
                value = __handleAttributeValue(value, handler);
              set.call(this, value);
            }
          ) : (value) => {
            if (typeof value === "string" || value === null)
              value = __handleAttributeValue(value, handler);
            this[storage] = value;
          };
          newGetter && (newGetter[isAttributeHandler] = true);
          newSetter[isAttributeHandler] = true;
          Object.defineProperty(location, prop, {
            enumerable: descriptor.enumerable,
            configurable: descriptor.configurable,
            get: newGetter,
            set: newSetter
          });
        }
      });
    }
  }
  const classFinishers = [...__classFinishers];
  __classFinishers.length = 0;
  function finishClass() {
    var _a3, _b2;
    for (const finisher of classFinishers)
      finisher(ElementDecorator);
    if (tagName && autoDefine)
      (_b2 = (_a3 = globalThis.window) == null ? void 0 : _a3.customElements) == null ? void 0 : _b2.define(tagName, ElementDecorator);
  }
  if (context == null ? void 0 : context.addInitializer) {
    context.addInitializer(finishClass);
  } else {
    finishClass();
  }
  return ElementDecorator;
}
function handlePreUpgradeValues(self) {
  if (!(self instanceof LumeElement))
    return;
  for (const [key, value] of self._preUpgradeValues) {
    if (!(key in self))
      continue;
    self._preUpgradeValues.delete(key);
    const desc = Object.getOwnPropertyDescriptor(self, key);
    if (desc && "value" in desc) {
      delete self[key];
    }
    self[key] = value;
  }
}
function __handleAttributeValue(value, handler) {
  return !handler ? value : value === null ? "default" in handler ? handler.default : null : handler.from ? handler.from(value) : value;
}
function descriptorError(prop) {
  throw new TypeError(`Missing descriptor for property "${String(prop)}" while mapping attributes to properties. Make sure the @element decorator is the first decorator on your element class, and if you're using 'static observedAttributes' or 'static observedAttributeHandlers' make sure you also define the respective class fields for the initial values. If a pre-existing class is already decoratored with other decorators, extend from it, then use @element directly on the subclass.`);
}
const classnames = ["container", "code", "line", "character", "textarea"];
const cache = /* @__PURE__ */ new Map();
function sheet(text) {
  if (text instanceof CSSStyleSheet) {
    return text;
  }
  if (!cache.has(text)) {
    const stylesheet = new CSSStyleSheet();
    stylesheet.replace(text);
    cache.set(text, stylesheet);
  }
  return cache.get(text);
}
let _initClass, _classDecs, _init_editable, _init_grammar, _init_stylesheet, _init_theme, _init_value, _init_textarea, _init_finalize;
function _applyDecs(e, t, r, n, o, a) {
  function i(e2, t2, r2) {
    return function(n2, o2) {
      return r2 && r2(n2), e2[t2].call(n2, o2);
    };
  }
  function c(e2, t2) {
    for (var r2 = 0; r2 < e2.length; r2++) e2[r2].call(t2);
    return t2;
  }
  function s(e2, t2, r2, n2) {
    if ("function" != typeof e2 && (n2 || void 0 !== e2)) throw new TypeError(t2 + " must " + (r2 || "be") + " a function" + (n2 ? "" : " or undefined"));
    return e2;
  }
  function applyDec(e2, t2, r2, n2, o2, a2, c2, u2, l2, f2, p2, d, h) {
    function m(e3) {
      if (!h(e3)) throw new TypeError("Attempted to access private element on non-instance");
    }
    var y, v = t2[0], g = t2[3], b = !u2;
    if (!b) {
      r2 || Array.isArray(v) || (v = [v]);
      var w = {}, S = [], A = 3 === o2 ? "get" : 4 === o2 || d ? "set" : "value";
      f2 ? (p2 || d ? w = { get: _setFunctionName(function() {
        return g(this);
      }, n2, "get"), set: function(e3) {
        t2[4](this, e3);
      } } : w[A] = g, p2 || _setFunctionName(w[A], n2, 2 === o2 ? "" : A)) : p2 || (w = Object.getOwnPropertyDescriptor(e2, n2));
    }
    for (var P = e2, j = v.length - 1; j >= 0; j -= r2 ? 2 : 1) {
      var D = v[j], E = r2 ? v[j - 1] : void 0, I = {}, O = { kind: ["field", "accessor", "method", "getter", "setter", "class"][o2], name: n2, metadata: a2, addInitializer: (function(e3, t3) {
        if (e3.v) throw Error("attempted to call addInitializer after decoration was finished");
        s(t3, "An initializer", "be", true), c2.push(t3);
      }).bind(null, I) };
      try {
        if (b) (y = s(D.call(E, P, O), "class decorators", "return")) && (P = y);
        else {
          var k, F;
          O.static = l2, O.private = f2, f2 ? 2 === o2 ? k = function(e3) {
            return m(e3), w.value;
          } : (o2 < 4 && (k = i(w, "get", m)), 3 !== o2 && (F = i(w, "set", m))) : (k = function(e3) {
            return e3[n2];
          }, (o2 < 2 || 4 === o2) && (F = function(e3, t3) {
            e3[n2] = t3;
          }));
          var N = O.access = { has: f2 ? h.bind() : function(e3) {
            return n2 in e3;
          } };
          if (k && (N.get = k), F && (N.set = F), P = D.call(E, d ? { get: w.get, set: w.set } : w[A], O), d) {
            if ("object" == typeof P && P) (y = s(P.get, "accessor.get")) && (w.get = y), (y = s(P.set, "accessor.set")) && (w.set = y), (y = s(P.init, "accessor.init")) && S.push(y);
            else if (void 0 !== P) throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
          } else s(P, (p2 ? "field" : "method") + " decorators", "return") && (p2 ? S.push(P) : w[A] = P);
        }
      } finally {
        I.v = true;
      }
    }
    return (p2 || d) && u2.push(function(e3, t3) {
      for (var r3 = S.length - 1; r3 >= 0; r3--) t3 = S[r3].call(e3, t3);
      return t3;
    }), p2 || b || (f2 ? d ? u2.push(i(w, "get"), i(w, "set")) : u2.push(2 === o2 ? w[A] : i.call.bind(w[A])) : Object.defineProperty(e2, n2, w)), P;
  }
  function u(e2, t2) {
    return Object.defineProperty(e2, Symbol.metadata || Symbol.for("Symbol.metadata"), { configurable: true, enumerable: true, value: t2 });
  }
  if (arguments.length >= 6) var l = a[Symbol.metadata || Symbol.for("Symbol.metadata")];
  var f = Object.create(null == l ? null : l), p = function(e2, t2, r2, n2) {
    var o2, a2, i2 = [], s2 = function(t3) {
      return _checkInRHS(t3) === e2;
    }, u2 = /* @__PURE__ */ new Map();
    function l2(e3) {
      e3 && i2.push(c.bind(null, e3));
    }
    for (var f2 = 0; f2 < t2.length; f2++) {
      var p2 = t2[f2];
      if (Array.isArray(p2)) {
        var d = p2[1], h = p2[2], m = p2.length > 3, y = 16 & d, v = !!(8 & d), g = 0 == (d &= 7), b = h + "/" + v;
        if (!g && !m) {
          var w = u2.get(b);
          if (true === w || 3 === w && 4 !== d || 4 === w && 3 !== d) throw Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + h);
          u2.set(b, !(d > 2) || d);
        }
        applyDec(v ? e2 : e2.prototype, p2, y, m ? "#" + h : _toPropertyKey(h), d, n2, v ? a2 = a2 || [] : o2 = o2 || [], i2, v, m, g, 1 === d, v && m ? s2 : r2);
      }
    }
    return l2(o2), l2(a2), i2;
  }(e, t, o, f);
  return r.length || u(e, f), { e: p, get c() {
    var t2 = [];
    return r.length && [u(applyDec(e, [r], n, e.name, 5, f, t2), f), c.bind(null, t2, e)];
  } };
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r);
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return String(t);
}
function _setFunctionName(e, t, n) {
  "symbol" == typeof t && (t = (t = t.description) ? "[" + t + "]" : "");
  try {
    Object.defineProperty(e, "name", { configurable: true, value: n ? n + " " + t : t });
  } catch (e2) {
  }
  return e;
}
function _checkInRHS(e) {
  if (Object(e) !== e) throw TypeError("right-hand side of 'in' should be an object, got " + (null !== e ? typeof e : "null"));
  return e;
}
const TmTextarea = createTmTextarea(Object.fromEntries(classnames.map((name) => [name, name])));
const TmTextareaStyleSheet = sheet(css);
_classDecs = [element("tm-textarea")];
let _TmTextareaElement;
const _TmTextareaElement2 = class _TmTextareaElement2 extends LumeElement {
  constructor() {
    super(...arguments);
    __publicField(this, "shadowOptions", {
      mode: "open",
      serializable: true
    });
    __publicField(this, "editable", _init_editable(this, true));
    __publicField(this, "grammar", _init_grammar(this, "tsx"));
    __publicField(this, "stylesheet", _init_stylesheet(this, ""));
    __publicField(this, "theme", _init_theme(this, "dark-plus"));
    __publicField(this, "value", _init_value(this, ""));
    __publicField(this, "textarea", _init_textarea(this, null));
    __privateAdd(this, _finalize, _init_finalize(this));
    __publicField(this, "template", () => {
      const _self$ = this;
      const adoptedStyleSheets = this.shadowRoot.adoptedStyleSheets;
      adoptedStyleSheets.push(TmTextareaStyleSheet);
      if (this.stylesheet) {
        adoptedStyleSheets.push(sheet(this.stylesheet));
      }
      return createComponent(TmTextarea, {
        textareaRef: (element2) => {
          _self$.textarea = element2;
        },
        get grammar() {
          return _self$.grammar;
        },
        get theme() {
          return _self$.theme;
        },
        get value() {
          return _self$.value;
        },
        get editable() {
          return _self$.editable;
        },
        onInput: (e) => _self$.value = e.currentTarget.value
      });
    });
  }
  get selectionStart() {
    return this.textarea.selectionStart;
  }
  set selectionStart(start) {
    this.textarea.selectionStart = start;
  }
  get selectionEnd() {
    return this.textarea.selectionEnd;
  }
  set selectionEnd(end) {
    this.textarea.selectionEnd = end;
  }
  setRangeText(replacement, start, end, selectMode) {
    this.textarea.setRangeText(replacement, start, end, selectMode);
    this.value = this.textarea.value;
  }
  setSelectionRange(selectionStart, selectionEnd, selectionDirection) {
    this.textarea.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }
  select() {
    this.textarea.select();
  }
};
_finalize = new WeakMap();
({
  e: [_init_editable, _init_grammar, _init_stylesheet, _init_theme, _init_value, _init_textarea, _init_finalize],
  c: [_TmTextareaElement, _initClass]
} = _applyDecs(_TmTextareaElement2, [[booleanAttribute, 0, "editable"], [stringAttribute, 0, "grammar"], [stringAttribute, 0, "stylesheet"], [stringAttribute, 0, "theme"], [stringAttribute, 0, "value"], [signal, 0, "textarea"], [signal, 0, "finalize", (o) => __privateGet(o, _finalize), (o, v) => __privateSet(o, _finalize, v)]], _classDecs, 0, (_) => __privateIn(_finalize, _), LumeElement));
_initClass();
let TmTextareaElement = _TmTextareaElement2;
function register() {
  if (!customElements.get("tm-textarea")) {
    customElements.define("tm-textarea", _TmTextareaElement);
  }
}
export {
  _TmTextareaElement as TmTextareaElement,
  register
};
